<template>
    <div class="county-one">
        <div class="county-one-header">
            <div class="county-one-header-title">{{data.name}}</div>
            <div class="county-one-header-data">
                <div class="county-one-header-data-one">
                    <div class="county-one-header-data-one-top">{{data.course_person_new_count}}</div>
                    <div class="county-one-header-data-one-bottom">新增学生数</div>
                </div>
                <div class="county-one-header-data-one">
                    <div class="county-one-header-data-one-top">{{data.course_person_count}}</div>
                    <div class="county-one-header-data-one-bottom">累计学生数</div>
                </div>
                <div class="county-one-header-data-one">
                    <div class="county-one-header-data-one-top">{{data.course_order_count}}</div>
                    <div class="county-one-header-data-one-bottom">领课订单数</div>
                </div>
            </div>
        </div>
        <div class="county-one-body">
            <div class="county-one-body-one" v-for="(item,index) in data.description_list" :key="data.name+index">
                {{item}}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { toRefs, onMounted } from "vue";
export default {
    components: {},
    props: {
        data: {
            default: {},
        },
    },
    setup(props: any) {
        let { data } = toRefs(props);

        onMounted(() => {});

        return {
            data,
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.county-one {
    width: 6.86rem;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 0.12rem;
    overflow: hidden;
    border-bottom: 0.01rem solid #ebedf0;
    margin-bottom: 0.42rem;
    &:last-child {
        border-bottom: 0;
    }
    &-header {
        width: 100%;
        height: 2.6rem;
        &-title {
            width: 100%;
            height: 1.04rem;
            font-size: 0.36rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #323233;
            background: #ffffff;
            line-height: 1.04rem;
            padding-left: 0.32rem;
            box-sizing: border-box;
        }
        &-data {
            width: 6.86rem;
            height: 1.56rem;
            background: #ffffff;
            box-shadow: 0rem 0.04rem 0.24rem 0rem rgba(100, 101, 102, 0.08);
            display: flex;
            justify-content: space-around;
            &-one {
                width: 1.4rem;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                &-top {
                    font-size: 0.32rem;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #323233;
                    line-height: 0.44rem;
                    text-shadow: 0px 0px 0px rgba(100, 101, 102, 0.08);
                    margin-bottom: 0.1rem;
                    margin-top: 0.4rem;
                }
                &-bottom {
                    font-size: 0.24rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #969799;
                    line-height: 0.4rem;
                    text-shadow: 0px 0px 0px rgba(100, 101, 102, 0.08);
                }
            }
        }
    }
    &-body {
        width: 6rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0.32rem auto 0;
        &-one {
            height: 0.56rem;
            line-height: 0.56rem;
            background: #f7f8fa;
            border-radius: 0.04rem;
            padding: 0 0.14rem;
            margin-right: 0.06rem;
            margin-bottom: 0.32rem;
            font-size: 0.24rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #323233;
        }
    }
}
</style>
