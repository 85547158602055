
import ClassOne from "./ClassOne.vue";
import { toRefs, onMounted } from "vue";
export default {
    components: { ClassOne },
    props: {
        data: {
            default: {},
        },
    },
    setup(props: any) {
        let { data } = toRefs(props);

        onMounted(() => {
            console.log(props);
        });

        return {
            data,
        };
    },
};
