
import { onMounted, ref } from "vue";
import Toast from "vant/es/toast";
import "vant/es/toast/style";
import SchoolOne from "../components/SchoolOne.vue";
import CountyOne from "../components/CountyOne.vue";
import ClassOne from "../components/ClassOne.vue";
import axios from "axios";

const getQueryString = (name:string) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) {
        return unescape(r[2]);
    }
    return null;
};

export default {
    components: {
        SchoolOne,
        CountyOne,
        ClassOne,
    },
    setup() {
        let data: any = ref({});
        let type = ref(-1);
        const key = getQueryString("id");
        const initData = async () => {
            const res: any = await axios.post(
                "/api/activity/link-data-export",
                {
                    key,
                }
            );
            if (res.data.code !== 0) {
                Toast(res.data.msg);
                return;
            }
            data.value = res.data.data;
            type.value = data.type;
            document.title = data.value.name
        };
        const subText = () => {
            switch (data.value.type) {
                case 0:
                    return `共有${data.value.list.length}个年级，${
                        data.value.list.flatMap((e: any) => e.list).length
                    }个班级`;
                case 1:
                case 2:
                case 3:
                    return `共有${data.value.list.length}个学校，${
                        data.value.list.flatMap((e: any) => e.description_list)
                            .length
                    }个班级`;
                // case 3:
                //     return `共有${data.value.list.length}个年级，${
                //         data.value.list.flatMap((e: any) => e.list).length
                //     }个班级`;
            }
        };

        onMounted(() => {
            initData();
        });

        return {
            data,
            subText,
        };
    },
};
