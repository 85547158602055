
import { toRefs, onMounted } from "vue";
export default {
    components: {},
    props: {
        data: {
            default: {},
        },
    },
    setup(props: any) {
        let { data } = toRefs(props);

        onMounted(() => {});

        return {
            data,
        };
    },
};
